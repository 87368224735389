.class{
    justify-content: center;
}

.imagenjunta1{
    width:100%;
    height: auto;
    padding-top: 30%;
}

.imagenjunta2{
    width: 100%;
    height: auto;
    padding-bottom: 10px;
}

.producto{
    width: 100%;
    height: 60%;
}

.imagen{
    width: 100%;
    height: 100%;
}

.elementor-button {
    font-family: "Roboto", Sans-serif;
    font-weight: 400;
    fill: rgb(137,106,105);
    color: rgb(137,106,105);
    background-color: #FFFFFF;
    border-style: solid;
    border-width: 3px 3px 3px 3px;
    border-color: rgb(137,106,105);
    border-radius: 25px 25px 25px 25px;
    width: 100%;
    height: 45px;
}

.elementor-button:hover{
    background-color: #33000096;
    color: #fff;
    border-color: rgb(137,106,105);
}

.stripe-button{
    width: 50%;
    border-radius: 10px 10px 10px 10px;
}

@media only screen and (max-width: 768px) {
    .imagen {
      width: 100%; 
      height: auto; 
    }

    .producto{
      width: 100%; 
      height: auto; 
    }
}
