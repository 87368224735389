.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .swiper {
    margin-left: auto;
    margin-right: auto;
  }

  .swiper-button-prev,
  .swiper-pagination-bullets,
  .swiper-button-next {
    color: #000000; 
  }
  
  .swiper-pagination-bullet, 
  .swiper-pagination-bullet-active{
    color: #000000;
    background-color: #000000;
  }