.footerColor{
    background-color: #000000;
    padding: 20px;
    text-align: center;
    margin-top: 20px;
}

.footer-img{
    width: 40%;
    height: 20%;
}

.margenb{
    margin-bottom: 30px;
}

.icon{
    width: 10%;
}