.imagenjunta1{
    width:100%;
    height: auto;
    padding-top: 30%;
}

.imagenjunta2{
    width: 100%;
    height: auto;
    padding-bottom: 10px;
}

.color-toast{
    background-color: #000000;
    color: #fff;
    border-radius: 20px 5px 20px 5px; 
    overflow: hidden;
    position: fixed;
    top: 20px;
    right: 5px;
    color: #fff;
}

.imgToast{
    width: 20%;

}