.floating-button {
    position: fixed;
    bottom: 15%; 
    right: 5px; 
    z-index: 999; 
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;
    text-decoration: none; color: inherit;
  
    }

    .button-content {
        display: flex;
        align-items: center;
    }

    .floating-button img {
        width: 50px; 
        height: 50px; 
        border-radius: 50%; 
        margin-right: 10px; 
    }

  .floating-button span {
        font-size: 14px; 
        background-color: #000000; 
        color: #fff;
        padding: 8px; 
        border-radius: 15px;
        border: 2px solid #fff;
    }