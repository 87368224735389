@font-face {
    font-family: 'Akrobat';
    src: url('../../assets/tipografia/Akrobat-SemiBold.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'HalenoirRegular';
    src: url('../../assets/tipografia/HalenoirCompactText-Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'HalenoirBold';
    src: url('../../assets/tipografia/HalenoirCompactText-Bold.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

.espacio{
    padding-top: 10%;
}

.label{
    font-size: 20pt;
    font-family: 'Akrobat', sans-serif;
}

.input{
    background-color: #f4f0ed;
    width: 100%;
    height: 60px;
    border: none;
    outline: none;
}

.input-area{
    background-color: #f4f0ed;
    width: 100%;
    height: 200px;
    border: none;
    outline: none;
    resize: none;
}

.enviar{
    border-radius: 25px 25px 25px 25px;
    border: 2px solid #000000;
    width: 25%;
    height: 25%;
    font-size: 17pt;
}

.enviar:hover{
    background-color: #000000;
    color: #fff;
}

.title{
    font-size: 36pt;
    font-weight: 550; 
    font-family: 'HalenoirRegular', sans-serif;
}

.subtitle{
    font-size: 36pt;
    font-weight: 800; 
        font-family: 'HalenoirBold', sans-serif;
}

.infotitle{
    font-size: 20pt;
    font-family: 'HalenoirBold', sans-serif;
    font-weight: bold;
}

.infotext{
    font-size: 20pt;
    font-family: 'Akrobat', sans-serif;
    line-height: 1.5; 
}

a{
    text-decoration: none;
    color: #000000;
}

.modalFondo{
    background-color: #687662;
    font-family: 'Akrobat', sans-serif;
    color: rgb(220,217,213);
}

.imgStyle{
    width: 70%;

}

.modalSize{
    width: 800px;
    max-width: 70%; /* Ajusta el valor según tus necesidades */
    margin: 0 auto;
}

@media (max-width: 550px) {

    .title{
        font-size: 18pt; 
    }
    
    .subtitle{
        font-size: 18pt;
    }

    .enviar{
        margin: 10px; 
        width: 50%; 
        height: 50px; 
        font-size: 10pt;
    }

    .label{
        font-size: 10pt; 
    }

    .input{
        height: 35px; 
        width: 100%; 
    }

    .input-area{
        width: 90%; 
        height: 230px; 
    }

    .titlemodal{
    font-size: 14pt;
    }

    .subtitlemodal{
        font-size: 10pt;
    }

}

@media (max-width: 770px){

    .title{
        font-size: 14pt; 
    }
    
    .subtitle{
        font-size: 14pt;
    }

    .label{
        font-size: 18pt; 
    }

    .enviar{
        margin: 10px; 
        width: 50%; 
        height: 50px; 
        font-size: 18pt;
    }

    .infotitle{
        font-size: 14pt;
    }

    .infotext{
        font-size: 14pt;
    }

    .titlemodal{
    font-size: 16pt;
    }

    .subtitlemodal{
        font-size: 12pt;
    }
}

@media (max-width: 820px){

    .title{
        font-size: 18pt; 
    }
    
    .subtitle{
        font-size: 18pt;
    }
    
    .enviar{
        margin: 10px; 
        width: 50%; 
        height: 50px; 
        font-size: 18pt;
    }

    .titlemodal{
    font-size: 22pt;
    }

    .subtitlemodal{
    font-size: 18pt;
    }
}