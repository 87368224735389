.homeImg{
  width: 100%;
  height: 100%;
}
.contenedorIMG{
    width: '100%';
    overflow: 'hidden';
}

.video{
  max-height: 500px;
    height: 100%;
}

iframe {
  width: 80%; 
  height: 700px; 
}


@media only screen and (max-width: 1024px) {
  iframe {
    width: 90%; 
    height: 500px; 
  }
}

@media only screen and (max-width: 600px) {
  iframe {
    width: 100%; 
    height: 300px; 
  }
}