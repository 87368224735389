@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap');

.navbarColor{
    background-color: #000000;
    height: 98px;
}

.img{
    padding-top: 10px;
    max-width: 70px;
    width: 100%;
    height: 85px;
}

.opcion{
    text-decoration: none;
    color: #fff;
    font-size: 18pt;
    font-family: 'Roboto Condensed', sans-serif;
    margin-right: 28px;
}

/* Estilos base para el texto */
.texto-con-zoom {
    transition: transform 0.3s ease; 
  }
  
/* Estilos para aplicar al hacer hover */
.texto-con-zoom:hover {
    transform: scale(1.3);
  }
  

