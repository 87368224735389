.elementor-button-clara {
    font-family: "Roboto", Sans-serif;
    font-weight: 400;
    fill: rgb(137,106,105);
    color: rgb(137,106,105);
    background-color: #FFFFFF;
    border-style: solid;
    border-width: 3px 3px 3px 3px;
    border-color: rgb(137,106,105);
    border-radius: 25px 25px 25px 25px;
    width: 200px;
    height: 45px;
}

.elementor-button-clara:hover{
    background-color: #33000096;
    color: #fff;
    border-color: rgb(137,106,105);
}

.stripe-button-clara{
    width: 200px;
    border-radius: 10px 10px 10px 10px;
}