.imagen{
    width: 100%;
    height: 100%;
}

@media only screen and (max-width: 768px) {
    .imagen {
      width: 100%; 
      height: auto; 
    }
  }