.colabImg{
    width: 100%;
    height: 100%;
}

.contenedorIMG{
    width: '100%';
    overflow: 'hidden';
}

.alinear{
    justify-content: center;
    align-items: center;
    margin: 0px;
    background-color: aqua;
}

.titleText{
    font-weight: 700;
    font-size: 40pt;
}

.textArea{
    font-size: 20pt;
}

